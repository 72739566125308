import React from 'react';
import PropTypes from 'prop-types';
import { useWizard } from '@oup/shared-front-end/src/components/Wizard';

import wizardStyles from './SelfRegisteredUserOnboardingWizard.scss';
import Link from '../Link/Link';
import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';
import withLocalizedContent from '../../language/withLocalizedContent';

function SelfRegisteredUserOnboardingChooseRoleWizardHeader({
  localizedContent: {
    selfRegisteredUserOnboardingWizard: selfRegisteredUserOnboardingWizardContent,
    hubGlossary: hubContent
  }
}) {
  const { step } = useWizard();
  const currentStep = step + 1;

  const renderTextForStep = () => {
    switch (currentStep) {
      case 1:
        return selfRegisteredUserOnboardingWizardContent.self_registered_user_onboarding_wizard_welcome;
      default:
        return '';
    }
  };

  return (
    <div className={wizardStyles.header}>
      <div className={wizardStyles.headerContainer}>
        <div className={wizardStyles.title}>
          <span>
            {selfRegisteredUserOnboardingWizardContent.self_registered_user_onboarding_wizard_getting_started}
          </span>{' '}
          {renderTextForStep()}
        </div>
        <div className={wizardStyles.helpAndSupport}>
          <Link
            dataTestId="SELF_REGISTERED_USER_ONBOARDING_WIZARD_SUPPORT_BUTTON"
            to={hubContent.help_and_support_link_to}
            target="_blank"
            role="button"
          >
            <SVGIcon glyph={GLYPHS.ICON_HELP_AND_SUPPORT} />
            <span className={wizardStyles.helpSupportText}>{hubContent.help_and_support}</span>
            <span className="a11y-hide">Help</span>
          </Link>
        </div>
      </div>
    </div>
  );
}

SelfRegisteredUserOnboardingChooseRoleWizardHeader.propTypes = {
  localizedContent: PropTypes.object.isRequired
};

export default withLocalizedContent(
  'selfRegisteredUserOnboardingWizard',
  'hubGlossary'
)(SelfRegisteredUserOnboardingChooseRoleWizardHeader);
