import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Wizard } from '@oup/shared-front-end/src/components/Wizard';
import CreateClassWizardHeader from './CreateClassWizardHeader/CreateClassWizardHeader.js';
import CreateClassWizardFooter from './CreateClassWizardFooter/CreateClassWizardFooter.js';
import CreateAClass from './CreateAClass/CreateAClass.js';
import AddStudents from './AddStudents/AddStudents.js';
import AssignProductOnboardingWizard from './AssignProductOnboardingWizard/AssignProductOnboardingWizard.js';
import Summary from './Summary/Summary.js';
import ClassOnboardingWarningModal from './ClassOnboardingWarningModal/ClassOnboardingWarningModal.js';
import WizardIntroScreen from './WizardIntroScreen/WizardIntroScreen.js';
import preventBodyScroll from '../../utils/dom/preventBodyScroll.js';

function ClassOnboardingWizard({ isOpen, content, breakpoint }) {
  const [isWarningModalOpen, setWarningModalOpen] = useState(false);

  const closeWizardWithWarningPopup = () => {
    setWarningModalOpen(true);
  };

  useEffect(() => {
    preventBodyScroll(isOpen);
  }, [isOpen]);

  return isOpen ? (
    <div data-testid="ONBOARDING_WIZARD_CONTAINER">
      <Wizard
        // TO DO: Replace the header when the new Header component is updated
        header={<CreateClassWizardHeader closeWizardWithWarningPopup={closeWizardWithWarningPopup} content={content} />}
        footer={<CreateClassWizardFooter content={content} />}
      >
        <WizardIntroScreen content={content} />
        <CreateAClass content={content} isOpen={isOpen} />
        <AddStudents content={content} isOpen={isOpen} />
        <AssignProductOnboardingWizard breakpoint={breakpoint} content={content} isOpen={isOpen} />
        <Summary content={content} isOpen={isOpen} />
      </Wizard>
      <ClassOnboardingWarningModal
        isOpen={isWarningModalOpen}
        content={content}
        closeWarningModal={() => setWarningModalOpen(false)}
      />
    </div>
  ) : null;
}

ClassOnboardingWizard.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  content: PropTypes.object.isRequired,
  breakpoint: PropTypes.string
};

export default ClassOnboardingWizard;
