import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Wizard } from '@oup/shared-front-end/src/components/Wizard';
import { connect } from 'react-redux';
import SelfRegisteredUserOnboardingOrganizationSetupWizard from './SelfRegisteredUserOnboardingOrganizationSetupWizard';
import OnboardingStudentSetupWizard from './OnboardingStudentSetupWizard/OnboardingStudentSetupWizard';
import SelfRegisteredUserOnboardingChooseRoleWizardFooter from './SelfRegisteredUserOnboardingChooseRoleWizardFooter';

import {
  openJoinClassWithCodeModal,
  showSelfSelectRoleModal,
  closeSelfRegisteredWizard
} from '../../redux/actions/hubUi';

import SelfRegisteredUserOnboardingWizardChooseRole from './SelfRegisteredUserOnboardingWizardChooseRole/SelfRegisteredUserOnboardingWizardChooseRole.js';
import SelfRegisteredUserOnboardingChooseRoleWizardHeader from './SelfRegisteredUserOnboardingChooseRoleWizardHeader.js';
import wizardStyles from './SelfRegisteredUserOnboardingWizard.scss';
import { HubLayoutConstants } from '../../globals/hubConstants.js';

function SelfRegisteredUserOnboardingChooseRoleWizard({
  isOpen,
  firstName,
  selectRole,
  closeSelfRegisteredWizardAction
}) {
  const [chooseRoleWizardCompleted, setChooseRoleWizardCompleted] = useState(false);
  const [selectedRole, setSelectedRole] = useState('');

  return (
    <div className={wizardStyles.wizardContainer} data-testid="SELF_REGISTERED_USER_ONBOARDING_WIZARD_CONTAINER">
      <Wizard
        header={<SelfRegisteredUserOnboardingChooseRoleWizardHeader />}
        footer={
          <SelfRegisteredUserOnboardingChooseRoleWizardFooter
            closeSelfRegisteredWizardAction={closeSelfRegisteredWizardAction}
            setChooseRoleWizardCompleted={setChooseRoleWizardCompleted}
          />
        }
      >
        <SelfRegisteredUserOnboardingWizardChooseRole
          isOpen={isOpen}
          selectRoleAction={selectRole}
          selectedRole={selectedRole}
          setSelectedRole={setSelectedRole}
        />
      </Wizard>
      {chooseRoleWizardCompleted && selectedRole === HubLayoutConstants.SELF_SELECT_ROLES[0].key && (
        <OnboardingStudentSetupWizard firstName={firstName} />
      )}
      {chooseRoleWizardCompleted && selectedRole === HubLayoutConstants.SELF_SELECT_ROLES[1].key && (
        <SelfRegisteredUserOnboardingOrganizationSetupWizard firstName={firstName} />
      )}
    </div>
  );
}

SelfRegisteredUserOnboardingChooseRoleWizard.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  firstName: PropTypes.string.isRequired,
  selectRole: PropTypes.func.isRequired,
  closeSelfRegisteredWizardAction: PropTypes.func
};

export default connect(null, {
  openJoinClassWithCodeModalAction: openJoinClassWithCodeModal,
  showSelfSelectRoleModalAction: showSelfSelectRoleModal,
  closeSelfRegisteredWizardAction: closeSelfRegisteredWizard
})(SelfRegisteredUserOnboardingChooseRoleWizard);
