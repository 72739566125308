import React, { useState } from 'react';
import PropTypes from 'prop-types';
// Components
import Button from '@oup/shared-front-end/src/components/Button/Button.js';
import { ICON_INTEGRATION } from '@oup/shared-front-end/src//svg/oup/index.js';
import Modal from '@oup/shared-front-end/src/components/Modal/Modal.js';
import ModalHead from '@oup/shared-front-end/src/components/Modal/ModalHead';
import ModalBody from '@oup/shared-front-end/src/components/Modal/ModalBody';
import RadioButtonGroup from '@oup/shared-front-end/src/components/RadioButtonGroup/RadioButtonGroup.js';
// Styles
import styles from './GradebookProgressFilters.scss';

function GradebookProgressFilters({ content, usePercentages, onUsePercentagesChange }) {
  const [progressSettingsOpen, setProgressSettingsOpen] = useState(false);

  return (
    <div>
      <Button
        text={content.progress_settings}
        variant="outline"
        size="base"
        icon={{ component: <ICON_INTEGRATION /> }}
        onClick={() => setProgressSettingsOpen(!progressSettingsOpen)}
      />
      <Modal
        open={progressSettingsOpen}
        variant="default"
        size="small"
        closeHandler={() => setProgressSettingsOpen(!progressSettingsOpen)}
      >
        <ModalHead
          onClose={() => setProgressSettingsOpen(!progressSettingsOpen)}
          heading={content.progress_settings}
          headingId="headingId"
        />
        <ModalBody>
          <RadioButtonGroup
            name="show-figures-as"
            checkedValue={usePercentages ? ['percentages'] : ['fractions']}
            variant="outline"
            hideLegend={false}
            legend={content.show_figures_as}
            radioGroup={[
              {
                label: content.percentages,
                value: 'percentages',
                id: 'option-percentages'
              },
              {
                label: content.fractions,
                value: 'fractions',
                id: 'option-fractions'
              }
            ]}
            onChange={e => onUsePercentagesChange(e.target.value === 'percentages')}
            classnames={{ container: styles.radioOptionsInline }}
          />
        </ModalBody>
      </Modal>
    </div>
  );
}

GradebookProgressFilters.propTypes = {
  content: PropTypes.object,
  usePercentages: PropTypes.bool,
  onUsePercentagesChange: PropTypes.func
};

export default GradebookProgressFilters;
