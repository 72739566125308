import React from 'react';
import PropTypes from 'prop-types';

const ELT_COVERS_URL = 'https://cf-aws.global.oup.com';

export function getCoverFromBank(isbn) {
  return `${ELT_COVERS_URL}/covers/medium/${isbn}_medium.jpg`;
}

export function getDefaultCoverImage() {
  return '/static/images/offline/default-cover.gif';
}

const onCoverImageError = event => {
  event.target.src = getDefaultCoverImage();
};

function Cover({ isbn, name }) {
  return <img src={getCoverFromBank(isbn)} alt={name} onError={onCoverImageError} />;
}

Cover.propTypes = {
  isbn: PropTypes.string.isRequired,
  name: PropTypes.string
};

export default Cover;
