import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Wizard } from '@oup/shared-front-end/src/components/Wizard';
import { connect } from 'react-redux';

import {
  openJoinClassWithCodeModal,
  showSelfSelectRoleModal,
  closeSelfRegisteredWizard
} from '../../../redux/actions/hubUi';

import OnboardingStudentSetupWizardFooter from './OnboardingStudentSetupWizardFooter';
import SelfRegisteredUserOnboardingChooseRoleWizardHeader from '../SelfRegisteredUserOnboardingChooseRoleWizardHeader.js';

import SelfRegisteredUserOnboardingWizardWelcome from '../SelfRegisteredUserOnboardingWizardWelcome/SelfRegisteredUserOnboardingWizardWelcome.js';
import SelfRegisteredUserOnboardingWizardChooseStudentCode from '../SelfRegisteredUserOnboardingWizardChooseStudentCode/SelfRegisteredUserOnboardingWizardChooseStudentCode.js';
import SelfRegisteredUserEnterACodeModal from '../SelfRegisteredUserEnterACodeModal/SelfRegisteredUserEnterACodeModal.js';

function OnboardingStudentSetupWizard({
  firstName,
  openJoinClassWithCodeModalAction,
  showSelfSelectRoleModalAction,
  closeSelfRegisteredWizardAction
}) {
  const [isEnterACodeModalOpen, setIsEnterACodeModalOpen] = useState(false);
  const [selectedStudentCodeOption, setSelectedStudentCodeOption] = useState('');

  const enterACodeModalHandleNext = () => {
    setIsEnterACodeModalOpen(false);
    showSelfSelectRoleModalAction(false);
    openJoinClassWithCodeModalAction();
    closeSelfRegisteredWizardAction();
  };

  return (
    <>
      <Wizard
        header={<SelfRegisteredUserOnboardingChooseRoleWizardHeader />}
        footer={<OnboardingStudentSetupWizardFooter selectedStudentCodeOption={selectedStudentCodeOption} />}
      >
        <SelfRegisteredUserOnboardingWizardWelcome firstName={firstName} />
        <SelfRegisteredUserOnboardingWizardChooseStudentCode
          closeSelfRegisteredWizardAction={closeSelfRegisteredWizardAction}
          selectedStudentCodeOption={selectedStudentCodeOption}
          setSelectedStudentCodeOption={setSelectedStudentCodeOption}
        />
      </Wizard>
      <SelfRegisteredUserEnterACodeModal
        isOpen={isEnterACodeModalOpen}
        onClose={() => setIsEnterACodeModalOpen(false)}
        onNext={enterACodeModalHandleNext}
      />
    </>
  );
}

OnboardingStudentSetupWizard.propTypes = {
  firstName: PropTypes.string.isRequired,
  openJoinClassWithCodeModalAction: PropTypes.func.isRequired,
  showSelfSelectRoleModalAction: PropTypes.func.isRequired,
  closeSelfRegisteredWizardAction: PropTypes.func
};

export default connect(null, {
  openJoinClassWithCodeModalAction: openJoinClassWithCodeModal,
  showSelfSelectRoleModalAction: showSelfSelectRoleModal,
  closeSelfRegisteredWizardAction: closeSelfRegisteredWizard
})(OnboardingStudentSetupWizard);
